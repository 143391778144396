(function() {
    'use strict';

    angular
        .module('coredynamicsApp')
        .controller('CounterpartyUploadController', CounterpartyUploadController);

    CounterpartyUploadController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'Upload', 'AlertService'];

    function CounterpartyUploadController($scope, $rootScope, $stateParams, previousState, Upload, AlertService ) {

        var vm = this;
        vm.previousState = previousState.name;
        vm.counterpartyId = $stateParams.id;
        vm.type = $stateParams.type;

        if(vm.type == 'counterparty') {
          vm.url = 'api/counterparties/import';
        } else if(vm.type == 'balance-sheets') {
          vm.url = 'api/balance-sheets/import';
        }

        $scope.upload = function (file) {

          if(!file) {
            return;
          }

            Upload.upload({
                url: vm.url,
                data: {file: file}
            }).then(function (resp) {
                console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);

                AlertService.success('coredynamicsApp.counterparty.message.importSuccessful', { param : resp.headers('X-magellan-params')});

            }, function (resp) {
                console.log('Error status: ' + resp.status);
                AlertService.error(resp.data.message, { param : resp.data.params});
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            });
        };

    }
})();
