(function() {
    'use strict';
    angular
        .module('coredynamicsApp')
        .factory('ActivityLog', ActivityLog);

    ActivityLog.$inject = ['$resource', 'DateUtils'];

    function ActivityLog ($resource, DateUtils) {
        var resourceUrl =  'api/activitylog';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET',
            	url: 'api/activitylog/:entities/:pageNo',
            	isArray: true,
              transformResponse: function (data) {

                data = angular.fromJson(data)

                  if(data) {
                    data.forEach(function(entity){
                      var date = moment(entity.logTime);
                      entity.logTime =date.format('L') + ' ' + date.format('HH:mm:ss');
                    })
                  }
                  return data;
              }
            }
        });
    }
})();
