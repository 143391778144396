(function() {
    'use strict';

    angular
        .module('coredynamicsApp')
        .controller('ApiKeyController', ApiKeyController);

    ApiKeyController.$inject = ['$scope', 'ApiKey', 'AlertService'];

    function ApiKeyController ($scope, ApiKey, AlertService) {
        var vm = this;

        ApiKey.get(function(success) {
            vm.apiKey = success;
            vm.apiKeyAvailable = true;
        }, function(error) {
            if(error.status === 404) {
                vm.apiKeyAvailable = false;
            } else {
                AlertService.error('Exception looking up API key. Please retry later.')
            }


        });

        vm.generateKey = function() {
            ApiKey.generateKey(function(success) {
                vm.apiKey = success;
                vm.apiKeyAvailable = true;
            }, function(error) {
                console.log(error);
                AlertService.error('Exception creating token')
            });
        }

        vm.deleteKey = function() {
            ApiKey.deleteKey(function(success) {
                vm.apiKey = null;
                vm.apiKeyAvailable = false;
            }, function(error) {
                console.log(error);
                AlertService.error('Exception deleting token')
            });
        }

    }
})();
