(function() {
    'use strict';

    angular
        .module('coredynamicsApp')
        .controller('CounterpartyDialogController', CounterpartyDialogController);

    CounterpartyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Counterparty'];

    function CounterpartyDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Counterparty) {
        var vm = this;

        vm.counterparty = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        $scope.$watch('vm.counterparty.category', function(category){
        	console.log(category);

        	if(category === 'INSURANCE') {
        		vm.subCategories = ['COMPOSITE', 'HEALTH', 'LIFE_ANNUITY_ACCIDENT', 'MULTI_LINE', 'PROPERTY_CASUALTY', 'OTHER_INSURANCE'];
        	} else if(category === 'FINANCIAL_INSTITUTION') {
        		vm.subCategories = ['ASSET_MANAGEMENT', 'CREDIT_SERVICES', 'MONEY_CENTER_BANKS', 'OTHER_FINANCIAL'];
        	} else if(category === 'CORPORATE') {
        		vm.subCategories = ['BASIC_MATERIALS', 'CONGLOMERATES', 'CONSUMER_GOODS', 'FINANCIAL', 'HEALTHCARE', 'INDUSTRIAL_GOODS', 'SERVICES', 'TECHNOLOGY', 'UTILITIES', 'OTHER_CORPORATE'];
        	}

        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.counterparty.id !== null) {
                Counterparty.update(vm.counterparty, onSaveSuccess, onSaveError);
            } else {
                Counterparty.save(vm.counterparty, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('coredynamicsApp:counterpartyUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
