(function() {
    'use strict';

    angular
        .module('coredynamicsApp')
        .factory('BalanceSheetSearch', BalanceSheetSearch);

    BalanceSheetSearch.$inject = ['$resource'];

    function BalanceSheetSearch($resource) {
        var resourceUrl =  'api/_search/balance-sheets/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
