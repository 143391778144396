(function() {
    'use strict';

    angular
        .module('coredynamicsApp')
        .directive('cdFormat', ['$filter', function ($filter) {
        return {
            require: '?ngModel',
            link: function (scope, elem, attrs, ctrl) {
                if (!ctrl) return;

                ctrl.$formatters.unshift(function (a) {

                  if(attrs.cdFormat === 'currency') {
                    return $filter(attrs.cdFormat)(ctrl.$modelValue, attrs.symbol, attrs.fractionSize)
                  } else {
                    return $filter(attrs.cdFormat)(ctrl.$modelValue)
                  }

                });

                ctrl.$parsers.push(function(val){
                  return val.replace(/^\$/, '').replace(/,/g, '')
                });

                elem.bind('blur', function(event) {
                    var plainNumber = elem.val().replace(/[^\d|\-+|\.+]/g, '');
                    if(attrs.cdFormat === 'currency') {
                      elem.val($filter(attrs.cdFormat)(plainNumber, attrs.symbol, attrs.fractionSize));
                    } else {
                      elem.val($filter(attrs.cdFormat)(plainNumber));
                    }

                });
            }
        };
}])
})();
