(function() {
	'use strict';

	angular.module('coredynamicsApp').controller('SubscriptionController',
			SettingsController);

	SettingsController.$inject = [ '$scope', '$rootScope', 'Principal', 'Auth',
			'JhiLanguageService', '$translate', 'Subscription', 'Account', 'AlertService' ];

	function SettingsController($scope, $rootScope, Principal, Auth,
			JhiLanguageService, $translate, Subscription, Account, AlertService) {
		var vm = this;

		var priceSmall = 1490;
		var priceEnterprise = 4990;

		vm.priceSmall = priceSmall.toLocaleString();
		vm.priceEnterprise = priceEnterprise.toLocaleString();


		var pricePerRatingSmall = 10;
		var pricePerRatingEnterprise = 5;

		vm.pricePerRatingSmall = pricePerRatingSmall.toLocaleString();
		vm.pricePerRatingEnterprise = pricePerRatingEnterprise.toLocaleString();

		// register load indicator listener
		$rootScope.$on("cfpLoadingBar:started", function() {
			vm.loading = true;
		});

		$rootScope.$on("cfpLoadingBar:loaded", function() {
			vm.loading = false;
		});

		Subscription.getSubscriptionInfo(function(data) {

			vm.loadSubscriptonData(data);

		}, function(error) {
			console.log(error);
			AlertService.error("subscription.error.cannotLoadSubscription");
		});

		Account.get(function(account) {
			vm.userEmail = account.data.email;
		}, function(error) {
			console.log(error);
		})

		vm.signup = function(plan) {

			var subscription = {
					plan: plan
			};

			Subscription.subscribe(subscription, function(data) {
				vm.loadSubscriptonData(data);
			}, function(error) {
				console.log(error);
			});
		}


		vm.cancel = function(plan) {
			Subscription.cancel({
				plan : plan
			}, function(data) {
				vm.loadSubscriptonData(data);
			}, function(error) {
				console.log(error);
			});
		}

		vm.loadSubscriptonData = function(data) {
			vm.loaded = true;
			vm.subscriptionState = data.subscriptionState;
			vm.currentSubscription = data.currentSubscription;
			vm.cancelAtPeriodEnd = data.cancelAtPeriodEnd;

			if (data.periodEndDate) {
				var trialEnd = moment(data.periodEndDate);
				vm.daysUntilTrialEnd = trialEnd.diff(moment.now(), 'days');
			}
		}

		vm.increaseQuantity = function() {
			vm.quantity = vm.quantity + 1;
		}

		vm.decreaseQuantity = function() {
			vm.quantity = vm.quantity - 1;
		}

		vm.isTrial = function() {
            return vm.isTrialingSmall() || vm.isTrialingEnterprise();
        }

		vm.isSignupSmall = function() {
			return ((vm.currentSubscription == 'SMALL' &&  vm.cancelAtPeriodEnd) || vm.currentSubscription != 'SMALL');
		}

		vm.isCancelSmall = function(){
			return vm.currentSubscription == 'SMALL' && !vm.cancelAtPeriodEnd && vm.paymentTerm == vm.currentPaymentTerm;
		}

		vm.isContactUs = function() {
			return vm.currentSubscription == 'SMALL' && !vm.cancelAtPeriodEnd && vm.paymentTerm != vm.currentPaymentTerm;
		}

		vm.isTrialingSmall = function() {
			return vm.subscriptionState == 'TRIAL' && vm.currentSubscription == 'SMALL' && vm.cancelAtPeriodEnd;
		}

		vm.isTrialExtendedSmall = function() {
			return vm.subscriptionState == 'TRIAL' && vm.currentSubscription == 'SMALL' && !vm.cancelAtPeriodEnd;
		}

		vm.isCancelledSmall = function() {
			return vm.subscriptionState == 'ACTIVE' && vm.currentSubscription == 'SMALL' && vm.cancelAtPeriodEnd;
		}

		vm.isCurrentSmall = function() {
			return vm.currentSubscription == 'SMALL' && !vm.cancelAtPeriodEnd && vm.subscriptionState != 'TRIAL';
		}

		vm.isContactUsEnterprise = function() {
			return ((vm.currentSubscription == 'ENTERPRISE' &&  vm.cancelAtPeriodEnd) || vm.currentSubscription != 'ENTERPRISE');
		}

		vm.isCancelEnterprice = function() {
			return vm.currentSubscription == 'ENTERPRISE' && !vm.cancelAtPeriodEnd && vm.currentQuantity == vm.quantity && vm.paymentTerm == vm.currentPaymentTerm;
		}

		vm.isChangeTermsEnterprise = function() {
			return vm.currentSubscription == 'ENTERPRISE' && !vm.cancelAtPeriodEnd && vm.paymentTerm != vm.currentPaymentTerm;
		}

		vm.isUpdateEnterprise = function() {
			return vm.currentSubscription == 'ENTERPRISE' && !vm.cancelAtPeriodEnd && vm.currentQuantity != vm.quantity && vm.paymentTerm == vm.currentPaymentTerm;
		}

		vm.isTrialingEnterprise = function() {
			return vm.subscriptionState == 'TRIAL' && vm.currentSubscription == 'ENTERPRISE' && vm.cancelAtPeriodEnd;
		}

		vm.isCancelledEnterprise = function() {
			return vm.subscriptionState == 'ACTIVE' && vm.currentSubscription == 'ENTERPRISE' && vm.cancelAtPeriodEnd;
		}

		vm.isTrialExtendedEnterprise = function() {
			return vm.subscriptionState == 'TRIAL' && vm.currentSubscription == 'ENTERPRISE' && !vm.cancelAtPeriodEnd;
		}

		vm.isCurrentEnterprise = function() {
			return vm.currentSubscription == 'ENTERPRISE' && !vm.cancelAtPeriodEnd && vm.subscriptionState != 'TRIAL';
		}

	}
})();
