(function() {

  'use strict';

  angular.module('coredynamicsApp').controller('HelpController',
      HelpController);

  HelpController.$inject = [ '$scope'];

  function HelpController($scope) {
    MathJax.Hub.Queue(["Typeset",MathJax.Hub]);
  }


})()
