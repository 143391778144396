(function() {
    'use strict';

    angular
        .module('coredynamicsApp')
        .controller('ActivityLogController', ActivityLogController);

    ActivityLogController.$inject = ['$scope', '$timeout', '$q', '$http', '$state', '$stateParams', 'ActivityLog', '$filter'];

    function ActivityLogController ($scope, $timeout, $q, $http, $state, $stateParams, ActivityLog, $filter) {
        var vm = this;

        vm.page = 1;
        vm.transition = transition;
        vm.activityLog = [];
        vm.totalItems = 10000;

        transition();

        function transition() {



          ActivityLog.query({entities: $stateParams.entityTypes, pageNo: (vm.page - 1)}, function(success){
            vm.activityLog  = success;

            if(success) {
              vm.activityLog.forEach(function(log){
                var key = 'activitylog.details.' + log.entityType + '_' + log.actionType;
                log.details = $filter('translate')(key, {param1: log.param1, param2: log.param2});
              })
            }

            if(vm.activityLog.length < 20 && vm.activityLog.length > 0) {
              vm.totalItems = 20*(vm.page - 1) + vm.activityLog.length;
            } else if (vm.activityLog.length == 0){
              vm.totalItems = 20*(vm.page - 1);
            }
          });

        }

      }
})();
