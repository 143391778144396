(function() {
    'use strict';
    angular
        .module('coredynamicsApp')
        .factory('ApiKey', ApiKey);

    ApiKey.$inject = ['$resource'];

    function ApiKey ($resource) {
        var resourceUrl =  'api/api-key';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET',
                url: 'api/api-key',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data)
                        return data;
                    }
                }
            },

            'generateKey': { method: 'GET',
                url: 'api/api-key/generate',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data)
                        return data;
                    }
                }
            },

            'deleteKey': { method: 'DELETE',
                url: 'api/api-key'
            }

        });
    }
})();
