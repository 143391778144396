(function() {
    'use strict';
    angular
        .module('coredynamicsApp')
        .factory('Subscription', Subscription);

    Subscription.$inject = ['$resource'];

    function Subscription ($resource) {
        var resourceUrl =  'api/subscription/';

        return $resource(resourceUrl, {}, {
            'getSubscriptionInfo': {
                method: 'GET',
                url: 'api/subscriptionInfo',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'subscribe': { method:'GET',
            	url: 'api/subscription/:plan'
            	
            },
            'cancel': { method:'GET',
            	url: 'api/subscription/:plan/cancel'
            	
            }
        });
    }
})();
