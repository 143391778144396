(function() {
    'use strict';
    angular
        .module('coredynamicsApp')
        .factory('Dashboard', Dashboard);

    Dashboard.$inject = ['$resource'];

    function Dashboard ($resource) {
        var resourceUrl =  'api/dashboard';

        return $resource(resourceUrl);
    }
})();
