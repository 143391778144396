(function() {
    'use strict';

    angular
        .module('coredynamicsApp')
        .controller('BalanceSheetDialogController', BalanceSheetDialogController);

    BalanceSheetDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', '$uibModalInstance', 'entity', 'BalanceSheet', 'Counterparty', 'RatingService'];

    function BalanceSheetDialogController ($timeout, $scope, $stateParams, $state, $uibModalInstance, entity, BalanceSheet, Counterparty, RatingService) {
        var vm = this;

        vm.balanceSheet = entity;

        if(!vm.balanceSheet.referenceDate) {
          vm.balanceSheet.referenceDate = moment().toDate();
        }

        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.computeRating = computeRating;
        vm.isLoading = true;
        $timeout(function(){vm.isLoading = false});

        vm.balanceSheet.counterpartyId = $stateParams.counterpartyId;

        if(!vm.balanceSheet.rating) {
        	vm.ratingAvailable = false;
          vm.ratingNotAvailable = false;
        } else {
          vm.formattedProbabilityOfDefault = Math.round(vm.balanceSheet.probabilityOfDefault * 100000) / 1000;
        }


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });


        vm.checkRatingAvailability = function(valid) {
        	if(((valid && !vm.balanceSheet.rating && $scope.editForm.$valid) || ($scope.editForm.$dirty && $scope.editForm.$valid)) && (!vm.isLoading && !vm.isSaving)) {
        		RatingService.available(vm.balanceSheet, function(data){

        		    if(data && data.ratingAvailable) {
                        vm.ratingAvailable = true;
                        vm.ratingNotAvailable = false;
                    } else {
                        vm.ratingAvailable = false;
                        vm.ratingNotAvailable = true;
                    }
              ;
            }, function(error){
              console.log(error);
              vm.ratingNotAvailable = true;
              vm.ratingAvailable = false;
            });

        	} else if (!vm.balanceSheet.rating  && !vm.isSaving){
            vm.ratingNotAvailable = true;
          }
        }

        $scope.$watch('editForm.$valid', vm.checkRatingAvailability);
        $scope.$watch('vm.balanceSheet', vm.checkRatingAvailability, true);


        function computeRating() {

        	if (vm.balanceSheet.id !== null) {
                RatingService.get({balanceSheetId : vm.balanceSheet.id}, function(data){
                  console.log(data);
                		vm.balanceSheet.rating = data.rating;
                    vm.balanceSheet.probabilityOfDefault = data.probabilityOfDefault;
                    vm.formattedProbabilityOfDefault = Math.round(data.probabilityOfDefault * 100000) / 1000;
                    vm.balanceSheet.referenceDate = moment(vm.balanceSheet.referenceDate).toDate();

                    $timeout(function(){$scope.editForm.$setPristine(); vm.isSaving = false;});
                	}, function(error){
                		console.log(error);
                    vm.ratingAvailable = false;
                    vm.ratingNotAvailable = true;
                    vm.isLoading = false;
                    vm.isSaving = false;
                	})
            }

        }

        function clear () {
            $uibModalInstance.close(vm.balanceSheet);
        }

        function save () {
            vm.isSaving = true
            if (vm.balanceSheet.id !== null) {
                BalanceSheet.update(vm.balanceSheet, onSaveSuccess, onSaveError);
            } else {
                BalanceSheet.save(vm.balanceSheet, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.balanceSheet = result;
            vm.ratingAvailable = false;
            vm.ratingNotAvailable = false;

            if(vm.balanceSheet.referenceDate) {
              vm.balanceSheet.referenceDate = moment(vm.balanceSheet.referenceDate).toDate();
            }

            computeRating()

            //$scope.$emit('coredynamicsApp:balanceSheetUpdate', result);
            //$uibModalInstance.close(result);

        }

        function onSaveError () {
            console.log("Error saving balance sheet")
            vm.ratingAvailable = false;
            vm.ratingNotAvailable = true;
            vm.isLoading = false;
            vm.isSaving = false;
        }



        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }
})();
