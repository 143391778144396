(function () {
    'use strict';

    angular
        .module('coredynamicsApp')
        .factory('RatingService', RatingService);

    RatingService.$inject = ['$resource'];

    function RatingService ($resource) {
        var service = $resource('api/balance-sheets/:balanceSheetId/rating', {}, {
            'get': {
                method: 'GET'
            },
            'available' : {
            	method : 'POST',
            	url: 'api/rating/available',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return {ratingAvailable : data};
                }
            }
        });

        return service;
    }
})();
