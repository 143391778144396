(function() {
    'use strict';

    angular
        .module('coredynamicsApp')
        .factory('CounterpartySearch', CounterpartySearch);

    CounterpartySearch.$inject = ['$resource'];

    function CounterpartySearch($resource) {
        var resourceUrl =  'api/_search/counterparties/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
