(function() {
    'use strict';

    angular
        .module('coredynamicsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('balance-sheet', {
            parent: 'entity',
            url: '/balance-sheet/{counterpartyId}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'coredynamicsApp.balanceSheet.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/balance-sheet/balance-sheets.html',
                    controller: 'BalanceSheetController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('balanceSheet');
                    $translatePartialLoader.addPart('rating');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                counterparty: ['Counterparty', '$stateParams', function(Counterparty, $stateParams) {
                    return Counterparty.get({id : $stateParams.counterpartyId}).$promise;
                }],
                previousState: [ '$state',
                  function($state) {
                    var currentStateData = {
                            name: $state.current.name,
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                    return currentStateData;
                  }

                ]
            }
        })
        .state('balance-sheet.upload', {
            parent: 'entity',
            url: '/balance-sheet/{id}/upload',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'coredynamicsApp.balanceSheet.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/balance-sheet/balance-sheet-upload.html',
                    controller: 'BalanceSheetUploadController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('balanceSheet');
                    $translatePartialLoader.addPart('rating');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'balance-sheet',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('balance-sheet-detail.edit', {
            parent: 'balance-sheet-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/balance-sheet/balance-sheet-dialog.html',
                    controller: 'BalanceSheetDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['BalanceSheet', function(BalanceSheet) {
                            return BalanceSheet.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('balance-sheet.new', {

            parent: 'balance-sheet',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/balance-sheet/balance-sheet-dialog.html',
                    controller: 'BalanceSheetDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                referenceDate: null,
                                companyIdentifier: null,
                                rating: null,
                                totalAssets: null,
                                cashAndCashEquivalents: null,
                                totalLiabilities: null,
                                shortTermDept: null,
                                longTermDept: null,
                                netIncome: null,
                                ebit: null,
                                interestExpenses: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('balance-sheet', null, { reload: true });
                }, function() {
                    $state.go('balance-sheet');
                });
            }]
        })
        .state('balance-sheet.edit', {
            parent: 'balance-sheet',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/balance-sheet/balance-sheet-dialog.html',
                    controller: 'BalanceSheetDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entity: ['BalanceSheet', function(BalanceSheet) {
                            return BalanceSheet.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('balance-sheet', null, { reload: true });
                }, function(reason) {
                    if(reason.country) {
                        $state.go('country-rating.edit', reason);
                    } else {
                        $state.go('^');
                    }
                });
            }]
        })
        .state('balance-sheet.delete', {
            parent: 'balance-sheet',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/balance-sheet/balance-sheet-delete-dialog.html',
                    controller: 'BalanceSheetDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['BalanceSheet', function(BalanceSheet) {
                            return BalanceSheet.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('balance-sheet', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
