(function() {
    'use strict';

    angular
        .module('coredynamicsApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'chart.js'
        ])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler', '$rootScope', '$timeout'];

    function run(stateHandler, translationHandler, $rootScope, $timeout) {
        stateHandler.initialize();
        translationHandler.initialize();

        $rootScope.$on('$stateChangeSuccess', function(angularEvent, currentPath, previousPath){
            try {
                if(currentPath && currentPath.url) {
                    $timeout(function(){
                        ga('set', 'page', currentPath.url);
                        ga('send', 'pageview');
                    });
                }
            } catch(err) {
                console.log('Exception updating analytics');
            }
        })

    }
})();
