(function() {
    'use strict';

    angular
        .module('coredynamicsApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'Dashboard', '$filter'];

    function HomeController ($scope, Principal, LoginService, $state, Dashboard, $filter) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.register = register;
        vm.dashboard = {};
        vm.loading = false;


        vm.radarChartColors = ['#8FBCB8'];

        vm.ratingHistOptions = {
            scale: {
                ticks: {
                    beginAtZero: true
                }
            }
        };


        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;

                if(vm.isAuthenticated()) {
                  Dashboard.get(function(data) {
                	  vm.dashboard = data;

                	  vm.ratingHistData = [];
                	  vm.ratingHistLabels = [];

                	  var ratings = vm.dashboard.latestRatingHistogram;

                      var ratingOrder = ['AAA', 'AA_PLUS', 'AA', 'AA_MINUS', 'A_PLUS', 'A', 'A_MINUS', 'BBB_PLUS', 'BBB', 'BBB_MINUS', 'BB_PLUS', 'BB', 'BB_MINUS', 'B_PLUS', 'B', 'B_MINUS', 'CCC_C']

                      ratingOrder.forEach(function(ratingOrder) {

                        var count = ratings[ratingOrder];

                        vm.ratingHistLabels.push($filter('translate')('coredynamicsApp.Rating.' + ratingOrder));
                		      vm.ratingHistData.push(count);

                    });

                  }, function(error){

                  });
                }


            });
        }
        function register () {
            $state.go('register');
        }
    }
})();
