(function() {

  'use strict';

  angular.module('coredynamicsApp').controller('TermController',
      TermController);

  TermController.$inject = [ '$scope'];

  function TermController($scope) {

  }


})()
