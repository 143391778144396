(function() {
    'use strict';

    angular
        .module('coredynamicsApp')
        .controller('CounterpartyController', CounterpartyController);

    CounterpartyController.$inject = ['$scope', '$state', '$q', '$http', 'Counterparty', 'CounterpartySearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function CounterpartyController ($scope, $state, $q, $http, Counterparty, CounterpartySearch, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.getFile = getFile;

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                CounterpartySearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Counterparty.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'name') {
                    result.push('name');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.counterparties = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'name';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }


        $scope.$watch('vm.myBlobObject', function(newValue, oldValue) {
          if(newValue!=undefined && newValue!=null){

                    var isFirefox = typeof InstallTrigger !== 'undefined';
                    var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
                    var isIE = /*@cc_on!@*/false || !!document.documentMode || window.navigator.userAgent.indexOf("Edge") > -1;
                    var isEdge = !isIE && !!window.StyleMedia;
                    var isChrome = !!window.chrome && !!window.chrome.webstore;
                    var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
                    var isBlink = (isChrome || isOpera) && !!window.CSS;

                    var fileName = 'ratings-' + moment().toDate().getTime() + '.xlsx';

                    if(isFirefox || isIE || isChrome){
                        if(isChrome){
                            var url = window.URL || window.webkitURL;
                            var fileURL = URL.createObjectURL(vm.myBlobObject);
                            var downloadLink = angular.element('<a></a>');//create a new  <a> tag element
                            downloadLink.attr('href',fileURL);
                            downloadLink.attr('download', fileName);
                            downloadLink.attr('target','_self');
                            downloadLink[0].click();//call click function
                            url.revokeObjectURL(fileURL);//revoke the object from URL
                        }
                        if(isIE){
                            window.navigator.msSaveOrOpenBlob(vm.myBlobObject, fileName);
                        }
                        if(isFirefox){

                            var url = window.URL || window.webkitURL;
                            var fileURL = url.createObjectURL(vm.myBlobObject);
                            var a = document.createElement('a');
                            a.style = "display: none";
                            a.href = fileURL;
                            a.download = fileName;
                            document.body.appendChild(a);
                            a.click();

                            $timeout(function(){
                              document.body.removeChild(a);
                              window.URL.revokeObjectURL(fileURL);
                            }, 100)

                        }


                    }else{
                        alert('Sorry, your browser is not compatible. Please let our support team know!');
                    }
                }
        })


        function getFile() {

          getStream({})
              .then(function(data){//is important that the data was returned as Aray Buffer
                vm.myBlobObject=new Blob([data],{ type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
              },function(fail){
                console.log('Download Error');
                console.log(fail);
                AlertService.error('Exception downloading file.')
                vm.myBlobObject=[];
              });
        };


        function getStream(params){

                 var deferred = $q.defer();
                 $http({
                     url:'api/counterparties?download=EXCEL',
                     method:"GET",//you can use also GET or POST
                     data:params,
                     headers:{'Content-type': 'application/json'},
                     responseType : 'arraybuffer',//THIS IS IMPORTANT
                    })
                    .success(function (data) {
                        deferred.resolve(data);
                    }).error(function (data) {
                         console.error("ERROR");
                         console.error(data);
                         deferred.reject(data);
                    });

                 return deferred.promise;
         };


    }
})();
