(function() {
    'use strict';

    angular
        .module('coredynamicsApp')
        .controller('BalanceSheetUploadController', BalanceSheetUploadController);

    BalanceSheetUploadController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'BalanceSheet', 'Counterparty', 'Upload', 'AlertService'];

    function BalanceSheetUploadController($scope, $rootScope, $stateParams, previousState, BalanceSheet, Counterparty, Upload, AlertService) {
        var vm = this;
        vm.previousState = previousState.name;
        vm.counterpartyId = $stateParams.id;

        var unsubscribe = $rootScope.$on('coredynamicsApp:balanceSheetUpdate', function(event, result) {
            vm.balanceSheet = result;
        });
        $scope.$on('$destroy', unsubscribe);

        $scope.upload = function (file) {

          if(!file) {
            return;
          }

            Upload.upload({
                url: 'api/balance-sheets/' + vm.counterpartyId  + '/import',
                data: {file: file}
            }).then(function (resp) {
                console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);

                AlertService.success('coredynamicsApp.balanceSheet.message.importSuccessful', { param : resp.headers('X-magellan-params')});

            }, function (resp) {
                console.log('Error status: ' + resp.status);
                AlertService.error(resp.data.message, { param : resp.data.params});
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            });
        };


    }
})();
