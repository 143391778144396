(function() {
    'use strict';

    angular
        .module('coredynamicsApp')
        .controller('BalanceSheetController', BalanceSheetController);

    BalanceSheetController.$inject = ['$scope', '$timeout', '$q', '$http', '$state', '$stateParams','BalanceSheet', 'BalanceSheetSearch', 'counterparty', 'previousState'];

    function BalanceSheetController ($scope, $timeout, $q, $http, $state, $stateParams, BalanceSheet, BalanceSheetSearch, counterparty, previousState) {
        var vm = this;

        vm.counterpartyId = $stateParams.counterpartyId;
        vm.counterparty = counterparty;

        vm.balanceSheets = [];
        vm.search = search;
        vm.loadAll = loadAll;
        vm.getFile = getFile;
        vm.myBlobObject = null;
        vm.roundProbability = roundProbability;

        if(previousState.name == 'counterparty') {
          vm.counterpartyUrlParams = previousState.params;
        } else {
          vm.counterpartyUrlParams = {};
        }

        loadAll();

        function loadAll() {
            BalanceSheet.query({counterpartyId : vm.counterpartyId}, function(result) {
                vm.balanceSheets = result;
            });
        }

        function search () {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            BalanceSheetSearch.query({query: vm.searchQuery}, function(result) {
                vm.balanceSheets = result;
            });
        }

        function roundProbability(prob) {
          return Math.round(prob * 100000) / 1000;
        }


        $scope.$watch('vm.myBlobObject', function(newValue, oldValue) {
          if(newValue!=undefined && newValue!=null){

                    var isFirefox = typeof InstallTrigger !== 'undefined';
                    var isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
                    var isIE = /*@cc_on!@*/false || !!document.documentMode || window.navigator.userAgent.indexOf("Edge") > -1;
                    var isEdge = !isIE && !!window.StyleMedia;
                    var isChrome = !!window.chrome && !!window.chrome.webstore;
                    var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
                    var isBlink = (isChrome || isOpera) && !!window.CSS;

                    var fileName = 'ratings-' + moment().toDate().getTime() + '.xlsx';

                    if(isFirefox || isIE || isChrome){
                        if(isChrome){
                            var url = window.URL || window.webkitURL;
                            var fileURL = URL.createObjectURL(vm.myBlobObject);
                            var downloadLink = angular.element('<a></a>');//create a new  <a> tag element
                            downloadLink.attr('href',fileURL);
                            downloadLink.attr('download', fileName);
                            downloadLink.attr('target','_self');
                            downloadLink[0].click();//call click function
                            url.revokeObjectURL(fileURL);//revoke the object from URL
                        }
                        if(isIE){
                            window.navigator.msSaveOrOpenBlob(vm.myBlobObject, fileName);
                        }
                        if(isFirefox){

                            var url = window.URL || window.webkitURL;
                            var fileURL = url.createObjectURL(vm.myBlobObject);
                            var a = document.createElement('a');
                            a.style = "display: none";
                            a.href = fileURL;
                            a.download = fileName;
                            document.body.appendChild(a);
                            a.click();

                            $timeout(function(){
                              document.body.removeChild(a);
                              window.URL.revokeObjectURL(fileURL);
                            }, 100)

                        }


                    }else{
                        alert('Sorry, your browser is not compatible. Please let our support team know!');
                    }
                }
        })


        function getFile() {

          getStream({})
              .then(function(data){//is important that the data was returned as Aray Buffer
                vm.myBlobObject=new Blob([data],{ type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
              },function(fail){
                console.log('Download Error');
                console.log(fail);
                AlertService.error('Exception downloading file.')
                vm.myBlobObject=[];
              });
        };


        function getStream(params){

                 var deferred = $q.defer();
                 $http({
                     url:'api/balance-sheets/counterpartyId/' + vm.counterpartyId + '?download=EXCEL',
                     method:"GET",//you can use also GET or POST
                     data:params,
                     headers:{'Content-type': 'application/json'},
                     responseType : 'arraybuffer',//THIS IS IMPORTANT
                    })
                    .success(function (data) {
                        deferred.resolve(data);
                    }).error(function (data) {
                         console.error("ERROR");
                         console.error(data);
                         deferred.reject(data);
                    });

                 return deferred.promise;
                };
      }
})();
